import React from "react";

export default function RightSection() {
  return (
    <div className="p-4 w-full lg:w-[60%]  bg-blue-100 ">
      <div className="flex-col w-full">Map embeded view</div>
      <div className="flex-col w-full">
        santa list when a particular hub is selected
      </div>
    </div>
  );
}
