import { AnimatePresence, motion } from "framer-motion";
import React, { memo, useCallback, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function GalleryModal({ parentStates, setParentStates }) {
  const handleClose = useCallback(() => {
    setParentStates((p) => ({
      ...p,
      galleryModal: {
        isRender: false,
      },
    }));
  }, [setParentStates]);

  return (
    <AnimatePresence>
      {parentStates.galleryModal.isRender && (
        <Modal
          handleClose={handleClose}
          data={parentStates.galleryModal.options?.data}
        />
      )}
    </AnimatePresence>
  );
}

export default GalleryModal;

const Modal = memo(({ handleClose, data }) => {
  // const [activeImg, setActiveImg] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isDetailsActive, setIsDetailsActive] = useState(false);

  return (
    <div className=" z-[999] flex-center fix-screen ">
      {/* background partial black layout */}
      <div
        onClick={handleClose}
        className="fix-screen z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateX: -400, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: -400, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className="w-[100dvw] h-[100dvh] z-[2] bg-[white] relative"
      >
        {/* heading */}
        <div className="px-4 h-[3rem] flex items-center justify-between flex-shrink-0 w-full border-b border-gray-200 py-3">
          <h1 className="font-semibold text-base md:text-lg">Gallery</h1>
          <RxCross2
            className="  cursor-pointer  text-2xl   text-[black] "
            onClick={handleClose}
          />
        </div>

        <div
          style={{
            height: "calc(100% - 3rem)",
          }}
          className="relative w-full flex flex-col justify-between"
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              height: "calc(100% - 6rem)",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="bg-cover bg-center w-full "
            onClick={() => setIsDetailsActive(false)}
          >
            {data?.map((obj, index) => (
              <SwiperSlide
                key={index}
                className="cursor-pointer bg-[#5a57573e]"
              >
                <img
                  src={obj?.img}
                  className="object-cover bg-center h-full mx-auto cursor-pointer relative"
                  alt="gallery"
                />
                <div
                  className={`absolute top-0 left-0 right-0 bottom-0 z-[3] `}
                >
                  <FaInfoCircle
                    className="absolute right-4 top-4 text-base lg:text-xl text-[#2136d4]"
                    onMouseEnter={() => setIsDetailsActive(true)}
                  />
                  <div className="absolute border border-gray-200 rounded-full bottom-4 glass left-4 text-sm font-semibold px-4 py-1 ">
                    {index + 1}/{data?.length}
                  </div>
                </div>
                <div
                  className={`absolute right-12 top-4 z-[4]  rounded-lg border border-gray-200 text-sm bg-white font-semibold p-4 max-w-[25rem] ${
                    isDetailsActive ? "block" : "hidden"
                  }`}
                >
                  <div className="flex gap-1 items-center ">
                    <h1 className="capitalize">{obj?.title}</h1>
                    {obj?.link && (
                      <Link to={obj?.link} target="_blank">
                        <FaExternalLinkAlt className="text-xs md:text-sm" />
                      </Link>
                    )}
                  </div>
                  <div className="text-xs lg:text-sm text-gray-500">
                    {obj?.description}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView="auto"
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className=" !h-[6rem] bg-gradient-to-r from-blue-200 to-white w-full  "
          >
            {data?.map((obj, index) => (
              <SwiperSlide key={index} className=" !w-auto px-4 py-3">
                <img
                  className="h-full border border-gray-200 rounded-lg"
                  src={obj?.img}
                  alt="gallery"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </motion.div>
    </div>
  );
});
