import { get_product_by_query_obj } from "../../api/productAPI";
import { getSantasInfoByQueryApi } from "../../api/santaApi";

export const dbGet = async ({
  collection,
  query = null,
  tags = [],
  onError = () => {},
  onSuccess = () => {},
  skip = 0,
  limit = 10000,
  sortingObj = { createdAt: "desc" },
  projectionString = "",
}) => {
  try {
    if (query === null) {
      onError("Query is required");
      return;
    }

    if (collection === "product") {
      const res = await get_product_by_query_obj({
        queryObject: query,
        skip,
        limit,
        sortingObj,
        projectionString,
        tags,
      });
      if (res.isSuccess) {
        onSuccess(res);
      } else {
        onError(res?.message || "Something went wrong");
      }
    }

    if (collection === "santa") {
      const res = await getSantasInfoByQueryApi({
        queryObject: query,
        skip,
        limit,
        sortingObj,
        projectionString,
        tags,
      });
      if (res.isSuccess) {
        onSuccess(res);
      } else {
        onError(res?.message || "Something went wrong");
      }
    }
  } catch (error) {
    onError(error?.message);
    console.error(error);
  }
};
