import React, { Fragment } from "react";
import "./index.css";
import ErrorBoundaries from "./components/ErrorComponents/ErrorBoundaries";
import ErrorPage from "./components/ErrorComponents/ErrorPage";
// ----------- toast -----------
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EboRouter from "./routes/EboRouter";
// ------------ context provider --------------------
import { AdminProvider } from "./context/AdminContext";
import { StoreProvider } from "./context/StoreContext";
import { ModalProvider } from "./context/ModalContext";
import "animate.css";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import "swiper/css"; // Import Swiper styles
// import io from "socket.io-client";

// const socket = io(process.env.REACT_APP_API_URL);

export default function App() {
  // const [popup, setPopup] = useState(null);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("Connected to server");
  //   });

  //   // Listen for the popupMessage event
  //   socket.on("popupMessage", (data) => {
  //     setPopup(data.message);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // const handleClick = () => {
  //   // Emit a custom event when the button is clicked
  //   socket.emit("buttonClicked", { message: "Button clicked!" });
  // };

  return (
    <Fragment>
      <ErrorBoundaries fallback={<ErrorPage />}>
        <StoreProvider>
          <ModalProvider>
            <AdminProvider>
              <EboRouter />
            </AdminProvider>
          </ModalProvider>
        </StoreProvider>
      </ErrorBoundaries>
      <ToastContainer limit={1} />
    </Fragment>
  );
}
