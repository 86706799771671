/**
 * This context will store the all modal opening and closing states
 */

import React, { createContext, useEffect, useState } from "react";
import { v2Api } from "../api/v2.api";
import { dbUpdate } from "../functions/windowFunc/dbUpdate";
import { getGooglePlaces } from "../functions/windowFunc/GoogleMapFun";
import { dbGet } from "../functions/windowFunc/dbGet";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  // ---------------------- ** STATES ** --------------------
  const [warningModal, setWarningModal] = useState({
    text: "Do you realy want to remove ?",
    isRender: false,
    funToBeExecuted: null,
    funToBeExecutedOnNo: null,
  });

  const [states, setStates] = useState({
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
    toast1: {
      isRender: false,
      text: "",
      options: {
        customZIndex: 1000,
      },
    },
    customPopup: {
      isRender: false,
      content: "",
    },
    DateRangeSelector1: {
      isRender: false,
      type: "",
      callBack: () => {},
    },
    weekSelector: {
      isRender: false,
      type: "",
      callBack: () => {},
    },
    LoadingScreen1: {
      isRender: false,
      msg: "",
    },
    askForInputs: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
    },
    askForRadio: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
      isOtherOptionAvailable: false,
    },
    enquiry: {
      isRender: false,
      options: null,
    },
    SelectAreaFilterModal: {
      isRender: false,
    },
    callsModal: {
      isRender: false,
      options: null,
    },
    viewChartData: {
      isRender: false,
      type: "",
      data: null,
    },
    FloatingCustomModal: {
      isRender: false,
      component: null,
      UIOptions: {},
      options: {},
    },
    updateEnquiryDetailsModal: {
      isRender: false,
      data: null,
      onSaveCB: () => {},
      onCancelCB: () => {},
    },

    /**
     * @dataType of data inside the options object
     * const ds = {
          id: "root",
          title: "root",
          childrens: [
            {
              id: "tags",
              title: "tags",
              childrens: [
                { id: "exotel", title: "exotel", isSelected: false },
                {
                  id: "interakt",
                  title: "interakt",
                },
              ],
            },
            {
              id: "others",
              title: "others",
              childrens: [
                { id: "atleast 1 order", title: "atleast 1 order", isSelected: false },
                {
                  id: "atleast 1 enquiry",
                  title: "atleast 1 enquiry",
                  isSelected: false,
                },
                {
                  id: "atleast 1 callLogs",
                  title: "atleast 1 callLogs",
                  isSelected: false,
                },
              ],
            },
          ],
        };
     */
    applyFilterModal: {
      isRender: false,
      options: {
        data: null,
        callbacks: {
          onApply: (arrayOfPaths) => {},
          onCancel: () => {},
        },
      },
    },

    galleryModal: {
      isRender: false,
      options: {
        data: null,
      },
    },
  });

  useEffect(() => {
    // in order to update any collection
    window.dbUpdate = (p) => dbUpdate(p);

    // in order to get data from any collection
    window.dbGet = (p) => dbGet(p);

    window.getGooglePlaces = (p) => getGooglePlaces(p);
  }, []);

  window.toast = (msg, type) => {
    // type : error
    setStates((p) => ({
      ...p,
      toast1: {
        isRender: true,
        text: msg,
        options: {
          customZIndex: 15,
          type: type,
        },
      },
    }));
  };

  window.floatingModal = (isRender, component, UIOptions, options) => {
    // options : { onHandleClose: () => {} }
    setStates((p) => ({
      ...p,
      FloatingCustomModal: {
        isRender,
        component,
        UIOptions,
        options,
      },
    }));
  };

  window.loadingScreen = (isLoading, msg) => {
    setStates((p) => ({
      ...p,
      LoadingScreen1: {
        isRender: isLoading,
        msg: msg || "",
      },
    }));
  };

  window.SelectAreaFilterModal = (isRender) => {
    setStates((p) => ({
      ...p,
      SelectAreaFilterModal: {
        isRender,
      },
    }));
  };

  window.showMsg = (message, type, callBackFunctionOnCross) => {
    // in order to show images, message: img url , type: 'img', "component"
    setStates((p) => ({
      ...p,
      warningModel: {
        msg: message,
        rightBtnText: "Ok",
        isRender: true,
        type: type || "",
        callBackFunctionOnCross: callBackFunctionOnCross || null,
      },
    }));
  };

  window.setDateRange = ({ selectors, type, callBack }) => {
    setStates((p) => ({
      ...p,
      DateRangeSelector1: { isRender: true, type, callBack, selectors },
    }));
  };

  window.weekSelector = ({ type, callBack }) => {
    setStates((p) => ({
      ...p,
      weekSelector: { isRender: true, type, callBack },
    }));
  };

  window.handleEnquery = (options) => {
    setStates((p) => ({
      ...p,
      enquiry: {
        isRender: true,
        options,
      },
    }));
  };

  window.openUrl = (url) => {
    window.open(url, "_blank");
  };

  window.customPopup = (content) => {
    setStates((p) => ({
      ...p,
      customPopup: {
        isRender: true,
        content: content,
      },
    }));
  };

  window.ask = (
    heading,
    subHeading,
    labels,
    saveBtnTxt,
    onSubmitCallback,
    values
  ) => {
    setStates((p) => ({
      ...p,
      askForInputs: {
        isRender: true,
        labels,
        values,
        heading,
        subHeading,
        onSubmitCallback,
        saveBtnTxt,
      },
    }));
  };

  window.askRadio = (
    heading,
    subHeading,
    labels,
    saveBtnTxt,
    onSubmitCallback,
    isOtherOptionAvailable,
    selectedLabelIndex
  ) => {
    // labels are an array of strings  will be shown in radio buttons
    // onSubmitCallback is a function which will be called on submit with chosen value
    setStates((p) => ({
      ...p,
      askForRadio: {
        isRender: true,
        labels,
        heading,
        subHeading,
        onSubmitCallback,
        saveBtnTxt,
        isOtherOptionAvailable,
        selectedLabelIndex,
      },
    }));
  };

  window.copyContent = (content, msg) => {
    // copy(content);
    navigator.clipboard.writeText(content);
    window.toast(msg);
  };

  window.removePopup = (popupKey) => {
    setStates((prevState) => {
      const newState = { ...prevState };
      if (popupKey === "warningModel") {
        newState.warningModel = {
          msg: "",
          leftBtnText: "",
          rightBtnText: "",
          isRender: false,
          funOnLeftBtn: () => {},
          funOnRightBtn: () => {},
        };
      } else if (popupKey === "toast1") {
        newState.toast1 = {
          isRender: false,
          text: "",
          options: {
            customZIndex: 1000,
          },
        };
      } else if (popupKey === "customPopup") {
        newState.customPopup = {
          isRender: false,
          content: "",
        };
      }

      return newState;
    });
  };

  window.warningModal = ({ msg, rb_text, lb_text, rb_fn, lb_fn }) => {
    setStates((p) => ({
      ...p,
      warningModel: {
        msg,
        leftBtnText: lb_text,
        rightBtnText: rb_text,
        funOnLeftBtn: lb_fn,
        funOnRightBtn: rb_fn,
        isRender: true,
      },
    }));
  };

  window.call = (options) => {
    /*
     options = {
        callerNumber: "6287945894",
        receiverNumber: "8102919931",
        receiverType: "User",
      }
    */
    setStates((p) => ({
      ...p,
      warningModel: {
        msg: "Do you want to make a call?",
        leftBtnText: "No",
        rightBtnText: "Yes",
        funOnLeftBtn: null,
        funOnRightBtn: () => {
          v2Api({
            tags: ["call"],
            operation: "makeOutgoingCallToConnectTwoNumbers",
            callerNumber: options.callerNumber,
            receiverNumber: options.receiverNumber,
          }).then((res) => {
            if (res.isSuccess) {
              setStates((p) => ({
                ...p,
                callsModal: {
                  isRender: true,
                  options: options,
                },
              }));
            } else {
              window.toast("Unable to make call", "error");
            }
          });
        },
        isRender: true,
      },
    }));
  };

  window.viewChartData = ({ type, data, title }) => {
    setStates((p) => ({
      ...p,
      viewChartData: {
        isRender: true,
        type,
        data,
        title,
      },
    }));
  };

  window.updateEnquiryDetailsModal = ({ data, onSaveCB, onCancelCB }) => {
    setStates((p) => ({
      ...p,
      updateEnquiryDetailsModal: {
        isRender: true,
        data,
        onSaveCB,
        onCancelCB,
      },
    }));
  };

  window.applyFilterModal = (options) => {
    setStates((p) => ({
      ...p,
      applyFilterModal: {
        isRender: true,
        options,
      },
    }));
  };

  window.gallery = (options) => {
    /*
    options.data[] type = {
      _id: order.productId._id,
      title: "title",
      description: "description",
      type: "product",
      img,
      link: `/product/decor/profile/${order.productId._id}`,
    }
    */
    setStates((p) => ({
      ...p,
      galleryModal: {
        isRender: true,
        options,
      },
    }));
  };

  return (
    <ModalContext.Provider
      value={{ states, setStates, warningModal, setWarningModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
