import React from "react";
import useGoogleMaps from "../../../hooks/maps/useGoogleMaps";

export default function LeftSection({ states, setStates }) {
  return (
    <div className="p-4 w-full lg:w-[40%] bg-blue-50 flex-col ">
      <PlaceSearchCont {...{ states, setStates }} />
      <div className="flex-col w-full">product search and presentation</div>
    </div>
  );
}

const PlaceSearchCont = ({ states, setStates }) => {
  const { placeSearchByText, getDetailsForPlaceID } = useGoogleMaps({});
  return (
    <div className="flex-col w-full">
      <input
        type="text"
        value={states?.placeSearch?.placeSearchBarText}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            placeSearch: {
              ...p.placeSearch,
              placeSearchBarText: e.target.value,
            },
          }));

          if (e.target.value.length > 3) {
            placeSearchByText({
              text: e.target.value,
              onSuccess: (predictions) => {
                console.log("predictions", predictions);
              },
              onError: (error) => {
                window.toast(error, "error");
              },
            });
          }
        }}
        placeholder="Search for a place"
        className="w-full p-2 border text-sm border-gray-300 rounded-xl"
      />
    </div>
  );
};
