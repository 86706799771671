import { useEffect, useState } from "react";
import { useStoreContext } from "../../context/StoreContext";

let timeoutId = null;
const COUNTRY = "IN";
let autocompleteService = null;

export default function useGoogleMaps({
  DEBOUNCE_DELAY_TIME = 1500,
  minSearchTextLengthToStartSearch = 3,
  mapContainerRef
}) {
  const { storeStates } = useStoreContext();

  const [states, setStates] = useState({});

  useEffect(() => {
    if (storeStates.isGoogleMapsScriptLoaded) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }
  }, [storeStates.isGoogleMapsScriptLoaded]);

  const debounce = (func) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, DEBOUNCE_DELAY_TIME);
    };
  };

  const handlePlacesSearch = (text, onSuccess, onError) => {
    try {
      if (!autocompleteService) {
        console.log("autocompleteService not loaded");
        timeoutId && clearTimeout(timeoutId);
        onError("autocompleteService not loaded");
        return;
      }

      if (text.length < minSearchTextLengthToStartSearch) {
        console.log("text length is less than minimum length");
        timeoutId && clearTimeout(timeoutId);
        return;
      }

      const handleSuggestions = (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.log("no predictions");
          onError("No predictions found");
          return;
        }
        console.log("predictions fetched");
        onSuccess(predictions, status);
      };

      autocompleteService.getPlacePredictions(
        {
          input: text,
          origin: { lat: 0, lng: 0 },
          componentRestrictions: {
            country: COUNTRY,
          },
        },
        handleSuggestions
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedSearch = debounce(({ text, onSuccess, onError }) =>
    handlePlacesSearch(text, onSuccess, onError)
  );

  const getDetailsForPlaceID = async (placeId) => {
    try {
      const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

      const response = await fetch(geocodingUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        return { data, lat: location.lat, lng: location.lng };
      } else {
        return { data: null, lat: null, lng: null };
      }
    } catch (error) {
      console.log(error);
      return { data: null, lat: null, lng: null };
    }
  };

  return {
    placeSearchByText: debouncedSearch,
    getDetailsForPlaceID,
  };
}
