import React, { useContext, useState } from "react";
import { FaAngleLeft, FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import MoreNavOptionsModal from "../../Layout/MoreNavOptionsModal";
import StoreContext from "../../context/StoreContext";
import AdminContext from "../../context/AdminContext";

// the main admin navbar
export default function AdminLayoutNavbar() {
  const [showMoreNavOptions, setShowMoreNavOptions] = useState(false);

  const { storeStates } = useContext(StoreContext);
  const {
    adminState: { adminInfo },
  } = useContext(AdminContext);

  const universalFilter = storeStates.universalFilter;

  const quickLinks = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "My profile",
      link: "/admin/profile/" + adminInfo?._id,
    },
    {
      label: "Orders",
      link: "/order",
    },
    {
      label: "Products",
      link: "/product",
    },
    {
      label: "Users",
      link: "/user",
    },
    {
      label: "Santa",
      link: "/team/santa",
    },
    {
      label: "Materials",
      link: "/inventory/mastersheet",
    },
    {
      label: "Place order",
      link: "/order/manual-order-place",
    },
    {
      label: "Call logs",
      link: "/call-logs",
    },
  ];

  return (
    <>
      <MoreNavOptionsModal
        showMoreNavOptions={showMoreNavOptions}
        setShowMoreNavOptions={setShowMoreNavOptions}
      />
      <nav className="fixed top-0 right-0 left-0 h-[4.5rem] justify-between whitespace-nowrap px-4 bg-[#2136d4] text-[white] z-[5] flex items-center ">
        <div
          style={{
            width: "calc(100% - 23rem)",
          }}
          className="flex items-center h-full  "
        >
          {window.location.pathname === "/" ? (
            <Link
              to={"/"}
              className="text-[2rem]  h-full  flex items-center justify-center cursor-pointer w-[5rem]   text-[white] font-[800] px-4 "
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              ebo
            </Link>
          ) : (
            <Link
              to={-1}
              className="flex cursor-pointer text-[1rem] z-[1] relative w-[5rem] h-full items-center px-4 gap-1 "
            >
              <FaAngleLeft className=" text-[1.25rem] " /> Back
            </Link>
          )}

          {/* quick links */}
          <div
            style={{
              width: "calc(100% - 5rem)",
            }}
            className=" gap-6 text-[1rem] h-full whitespace-nowrap overflow-scroll hidden md:flex items-center"
          >
            {quickLinks.map((curr, index) => {
              return (
                <Link
                  key={index}
                  to={curr.link}
                  className=" hover:underline   "
                >
                  {curr.label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="flex gap-4 items-center text-[.875rem] w-[23rem] justify-end  ">
          <a
            href="https://www.ebonow.com"
            target="_blank"
            rel="noreferrer"
            className=" hover:underline cursor-pointer hidden md:block text-[white] font-[800] "
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
          >
            Visit ebo
          </a>
          <div
            onClick={() => {
              window.SelectAreaFilterModal(true);
            }}
            className="pr-4 pl-6 capitalize bg-white py-1 cursor-pointer rounded text-black flex gap-1 items-center "
          >
            {universalFilter.city === "all"
              ? "All Cities"
              : universalFilter.hub === "all"
              ? universalFilter.city
              : universalFilter.hub}
            <FaCaretDown />
          </div>
          {/* universal filter city/hub selection */}
          <GiHamburgerMenu
            onClick={() => {
              setShowMoreNavOptions(true);
            }}
            className="text-[1.5rem] cursor-pointer "
          />
        </div>
      </nav>
    </>
  );
}
