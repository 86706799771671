import React, { useMemo, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";

import { FaCity } from "react-icons/fa";
import { useStoreContext } from "../../../context/StoreContext";
import { useNavigate } from "react-router-dom";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

export default function ServiceCheckIndex() {
  const {
    storeStates: {
      universalFilter = {
        city: "all",
        hub: "all",
      },
      allHubs,
      hubBoundaries,
    },
  } = useStoreContext();

  const [states, setStates] = useState({
    placeSearch: {
      placeSearchBarText: "",
    },
  });

  return (
    <div className="fix-screen bg-white z-[999] flex-col">
      <TopHeader {...{}} />
      <HubSelectionChips {...{}} />
      <div className="w-full flex flex-wrap">
        <LeftSection {...{ states, setStates }} />
        <RightSection />
      </div>
    </div>
  );
}

const HubSelectionChips = () => {
  const {
    storeStates: {
      universalFilter = {
        city: "all",
      },
      hubsByCity,
    },
    setStoreStates,
  } = useStoreContext();

  const selectedCityHubs = useMemo(() => {
    if (universalFilter.city === "all") {
      return [];
    }
    return (
      hubsByCity.find((city) => city.city === universalFilter.city)?.hubs || 0
    );
  }, [universalFilter, hubsByCity]);

  if (selectedCityHubs.length === 0) return null;

  return (
    <div className=" w-full bg-blue-100 py-2 px-2 md:px-4 whitespace-nowrap ">
      <Swiper
        spaceBetween={10}
        slidesPerView="auto"
        freeMode={true}
        grabCursor={true}
        className=""
      >
        <SwiperSlide className=" !w-auto ">
          <div
            onClick={() => {
              setStoreStates((p) => ({
                ...p,
                universalFilter: {
                  ...p.universalFilter,
                  hub: "all",
                },
              }));
            }}
            className={` ${
              universalFilter.hub === "all"
                ? "blue-gradient"
                : "border border-gray-200 bg-white   "
            }  flex-shrink-0 rounded-xl cursor-pointer px-4 py-1.5 flex-col `}
          >
            <div className=" first-letter:capitalize font-medium text-xs md:text-sm ">
              All Hubs
            </div>
          </div>
        </SwiperSlide>
        {selectedCityHubs.map((currHub, idx) => (
          <SwiperSlide key={idx} className=" !w-auto ">
            <div
              onClick={() => {
                setStoreStates((p) => ({
                  ...p,
                  universalFilter: {
                    ...p.universalFilter,
                    hub: currHub._id,
                  },
                }));
              }}
              className={` ${
                universalFilter.hub === currHub._id
                  ? "blue-gradient"
                  : "border border-gray-200 bg-white   "
              }  flex-shrink-0 rounded-xl cursor-pointer px-4 py-1.5 flex-col `}
            >
              <div className=" first-letter:capitalize  font-medium text-xs md:text-sm ">
                {currHub.name}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const TopHeader = () => {
  const nav = useNavigate();

  const {
    storeStates: { universalFilter = {}, hubsByCity },
    setStoreStates,
  } = useStoreContext();

  return (
    <div className="flex items-center w-full flex-shrink-0 px-4 py-3 justify-between blue-gradient overflow-scroll whitespace-nowrap">
      <div
        onClick={() => {
          nav(-1);
        }}
        className="flex items-center gap-2 text-sm lg:text-lg cursor-pointer font-semibold"
      >
        <FaAngleLeft className="" />
        <div className=" flex flex-col w-full">
          <div className=" font-[600] text-base lg:text-lg">Service check</div>
        </div>
      </div>
      <div className="p-1 px-2  bg-blue-100 rounded-lg text-black text-xs md:text-sm flex items-center gap-2 font-semibold">
        <FaCity className="text-[#2136d4] text-lg" />
        <select
          value={universalFilter.city}
          onChange={(e) =>
            setStoreStates((p) => ({
              ...p,
              universalFilter: {
                ...p.universalFilter,
                city: e.target.value,
                hub: "all",
              },
            }))
          }
          className="p-1 px-2 md:px-6 capitalize bg-blue-50 rounded-lg text-xs md:text-sm border border-blue-300"
        >
          <option value="all">All</option>
          {hubsByCity?.map?.((currCity) => {
            return (
              <option value={currCity.city} key={currCity.city}>
                {currCity.city}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
